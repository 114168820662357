import { useMemo } from 'react'

interface YieldTooltipProps {
  yieldData: { value?: number; lastUpdated: string }
  baseTooltip?: string
}

export const useFormatYieldTooltip = ({ baseTooltip = '', yieldData }: YieldTooltipProps) => {
  return useMemo(() => {
    const yieldApy = yieldData.value ? `${yieldData.value?.toFixed(2)}%` : undefined

    const lastUpdated = yieldData.lastUpdated
    const tooltip = lastUpdated ? `${baseTooltip}\n\nLast Updated: ${lastUpdated}` : baseTooltip

    return [yieldApy, tooltip]
  }, [yieldData, baseTooltip])
}
