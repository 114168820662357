'use client'

import { usePathname, useSearchParams } from 'next/navigation'

import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'

export const usePosthog = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const posthog = usePostHog()

  useEffect(() => {
    // Track pageviews
    if (pathname && posthog) {
      let url = window.origin + pathname

      if (searchParams?.toString()) {
        url = url + `?${searchParams.toString()}`
      }

      posthog.group('ethena-labs', 'ethena')

      posthog.capture('$pageview', {
        $current_url: url,
      })
    }
  }, [pathname, searchParams, posthog])
}
