'use client'

import { useMemo } from 'react'
import useSWR from 'swr'
import { erc20Abi } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

import { cn } from '../../utils'
import { TOKEN_ADDRESSES } from '../constants/Tokens'
import { useIsHydrated } from '../hooks/useIsHydrated'
import { usePollRpc } from '../hooks/usePollRpc'
import { useSusdeYieldTooltipData } from '../hooks/useSusdeYieldTooltipData'
import { FetchError } from '../types'
import { RewardsQuery } from '../types/graphql/graphql'
import { UserCountResponse } from '../utils/edge/user-count'
import { formatBigInt, formatNumber, NumberType } from '../utils/formatNumbers'

import Placeholder from './Placeholder'
import { StatWithIcon } from './StatWithIcon'
import { YieldTooltip } from './YieldTooltip'

interface StatsProps {
  isIdle?: boolean
  userRewards?: boolean
  isMobileMenuStats?: boolean
}

export const Stats = ({ isIdle, isMobileMenuStats = false, userRewards }: StatsProps) => {
  const { address } = useAccount()

  const { data: userCount } = useSWR<UserCountResponse, FetchError>('/api/user-count')
  const { data: userRewardsData } = useSWR<RewardsQuery>(address ? `/api/rewards?address=${address}` : undefined)

  const { data: tvlData, queryKey } = useReadContract({
    abi: erc20Abi,
    address: TOKEN_ADDRESSES.USDe,
    chainId: 1,
    functionName: 'totalSupply',
  })

  usePollRpc({ interval: 20, isIdle, queryKey })

  const { tooltip, yieldApy, yieldStr } = useSusdeYieldTooltipData()

  const isHydrated = useIsHydrated()

  const tvl = useMemo(() => {
    if (!isHydrated || !tvlData) return undefined
    return formatBigInt(tvlData, 'USDe', NumberType.FiatTokenAmt)
  }, [isHydrated, tvlData])

  return (
    <div className="flex flex-wrap xs:gap-1.5 gap-0 sm:gap-1.5">
      <StatWithIcon
        imgSrc="/shared/usde.svg"
        label="TVL"
        value={tvl}
        classes={cn(isMobileMenuStats ? 'lg:hidden' : 'hidden lg:flex')}
      />
      <hr
        className={cn(
          'w-[1px] h-full mt-0.5 min-h-10 border border-white/15',
          isMobileMenuStats ? 'lg:hidden' : 'hidden lg:flex',
        )}
      />
      <StatWithIcon
        imgSrc="/shared/sUSDe.svg"
        label="APY"
        value={yieldStr}
        tooltip={<YieldTooltip yieldStr={yieldApy} tooltip={tooltip} />}
        imgClasses="flex"
        classes={cn(isMobileMenuStats ? 'sm:hidden' : 'sm:flex')}
      />
      <hr
        className={cn(
          'w-[1px] h-full mt-0.5 min-h-10 border border-white/15',
          isMobileMenuStats ? 'sm:hidden' : 'sm:hidden xl:flex',
        )}
      />

      {isHydrated ? (
        <>
          {userRewards && address && userRewardsData?.queryWallet?.[0]?.accumulatedTotalShardsEarned ? (
            <div className={cn(isMobileMenuStats ? 'flex items-center xl:hidden' : 'hidden xl:flex items-center')}>
              <StatWithIcon
                imgSrc="/shared/rewards-small.svg"
                label="Rewards"
                value={
                  userRewardsData?.queryWallet?.[0]?.accumulatedTotalShardsEarned
                    ? formatNumber(Math.round(userRewardsData?.queryWallet?.[0]?.accumulatedTotalShardsEarned))
                    : '0'
                }
              />
            </div>
          ) : (
            <StatWithIcon
              imgSrc="/shared/ethena.svg"
              label="Users"
              value={userCount?.count}
              classes={cn(isMobileMenuStats ? 'flex items-center xl:hidden' : 'hidden xl:flex items-center')}
            />
          )}
        </>
      ) : (
        <div className="hidden xl:flex flex-col items-start justify-center px-4 w-fit py-[3px] gap-0.5">
          <div className="flex items-center gap-1 justify-center h-full">
            <Placeholder isLoading classes="w-3 h-3 flex items-center">
              {null}
            </Placeholder>
            <Placeholder isLoading classes="w-[32px] h-3.5">
              {null}
            </Placeholder>
          </div>
          <Placeholder isLoading classes="w-[32px] h-3.5 mt-1">
            {null}
          </Placeholder>
        </div>
      )}
    </div>
  )
}
