/* eslint-disable max-lines */
import { Abi, Address, zeroAddress } from 'viem'

import { STAKED_USDE_V2_ABI, USDE_ABI, USDT_ABI } from '../constants/abi'
import { CoinGeckoSymbol, TokenType } from '../types'

import { IS_PROD_ENV } from './is-production'

const USDE_ADDRESS = IS_PROD_ENV
  ? '0x4c9EDD5852cd905f086C759E8383e09bff1E68B3'
  : '0x2dFaF238B8255826A160432126E0BC5db20c33e9'

// stETH serves as mock token in UAT for testing purposes
const STETH_ADDRESS = IS_PROD_ENV
  ? '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84'
  : '0xe90eC12369FbbCBCDaAa320DA59134281458D16B'

const ENA_ADDRESS = process.env.NEXT_PUBLIC_IS_SENA_TESTING
  ? '0x1CdeF1eE3f3b170c5c5FF7c252a9a017B2E4E3C8'
  : '0x57e114B691Db790C35207b2e685D4A43181e6061'

const sENA_ADDRESS = process.env.NEXT_PUBLIC_IS_SENA_TESTING
  ? '0x661ED445C389315A60e1931BAd0B6Fa22e6D519c'
  : '0x8bE3460A480c80728a8C4D7a5D5303c85ba7B3b9'

export const TOKEN_ADDRESSES: Record<TokenType, Address> = {
  AaveLidoSusde: '0xc2015641564a5914A17CB9A92eC8d8feCfa8f2D0',
  'Ambient USDe • ETH': zeroAddress,
  'Angle USDa': '0x0000206329b97db379d5e1bf586bbdb969c63274',
  ASUSDe: '0x4579a27aF00A62C0EB156349f31B345c08386419',
  AUSD: zeroAddress,
  AUSDe: '0x4F5923Fc5FD4a93352581b38B7cD26943012DECF',
  'Avalon USDa': '0x8A60E489004Ca22d775C5F2c657598278d17D9c2',
  BBUSD: zeroAddress,
  BTC: zeroAddress,
  cmETH: zeroAddress,
  crvUSD: '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
  DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  ENA: ENA_ADDRESS,
  ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  'Ether.fi Liquid': '0x08c6F91e2B681FaF5e17227F2a44C307b3C1364C',
  eUSD: '0x939778D83b46B456224A33Fb59630B11DEC56663',
  FDUSD: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
  FRAX: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  FRAXFraxtal: '0xfc00000000000000000000000000000000000001',
  GHO: '0x40D16FC0246aD3160Ccc09B8D0D3A2cD28aE6C2f',
  GYD: '0xe07f9d810a48ab5c3c914ba3ca53af14e4491e8a',
  'Locked ENA': zeroAddress,
  mETH: '0xd5F7838F5C461fefF7FE49ea5ebaF7728bB0ADfa',
  mkUSD: '0x4591DBfF62656E7859Afe5e45f6f47D3669fBB28',
  'MOE USDe • AUSD': zeroAddress,
  'MOE USDe • cmETH': zeroAddress,
  'MOE USDe • mETH': '0x74Db2EB31D4B5a0522d3c2eB6dBd87aAc21745f9',
  'MOE USDe • sUSDe': zeroAddress,
  'MOE USDe • USDC': zeroAddress,
  'MOE USDe • USDT': '0x7bC5Cd04Dd1211a445444dfda6aE11Af27367a2c',
  'PT ENA': '0x9946c55a34cd105f1e0cf815025eaecff7356487',
  'PT ENA OCT': '0xAfA002De2DADb57B2b04e32aa4F42a69AdEBF2FD',
  'PT rsENA': '0x74fd29b63766e9d05ed9d1181a365522d096ed6f',
  PTUSDeJuly: '0xa0021EF8970104c2d008F38D92f115ad56a9B8e1',
  RateX: zeroAddress,
  rsENA: '0xc65433845ecD16688eda196497FA9130d6C47Bd8',
  rsUSDe: '0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26',
  scrvUSD: '0x0655977feb2f289a4ab78af67bab0d17aab84367',
  sDAI: '0x83F20F44975D03b1b09e64809B757c47f942BEeA',
  sENA: sENA_ADDRESS,
  sFRAX: '0xe3b3fe7bca19ca77ad877a5bebab186becfad906',
  SolvBTC: zeroAddress,
  stETH: STETH_ADDRESS,
  sUSDe: '0x9D39A5DE30e57443BfF2A8307A4256c8797A3497',
  'sUSDe • crvUSD': zeroAddress,
  'sUSDe • FRAX Fraxtal': '0x8b4e5263e8d6cc0bbf31edf14491fc6077b88229',
  'sUSDe • GYD': zeroAddress,
  'sUSDe • PYUSD': zeroAddress,
  'sUSDe • scrvUSD': '0xd29f8980852c2c76fc3f6e96a7aa06e0bedcc1b1',
  'sUSDe • sDAI': '0x167478921b907422F8E88B43C4Af2B8BEa278d3A',
  'sUSDe • sDAI • sFRAX': zeroAddress,
  'sUSDe • sFRAX': zeroAddress,
  'sUSDe • USDB': zeroAddress,
  'sUSDe • USDC': zeroAddress,
  'sUSDe • USDC • USDT': zeroAddress,
  'sUSDe • USDC.e': zeroAddress,
  'sUSDe • USDT': zeroAddress,
  'sUSDe • wstETH': zeroAddress,
  sUSDeFraxtal: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
  'Tempest USDe • ETH': zeroAddress,
  USDB: '0x4300000000000000000000000000000000000003',
  USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  USDe: USDE_ADDRESS,
  'USDe • crvUSD': '0xF55B0f6F2Da5ffDDb104b58a60F2862745960442',
  'USDe • DAI': '0xF36a4BA50C603204c3FC6d2dA8b78A7b69CBC67d',
  'USDe • FDUSD': '0xF8dB2ACcdEf8e7a26b0E65C3980ADC8ce11671a4',
  'USDe • FRAX': '0x5dc1BF6f1e983C0b21EfB003c105133736fA0743',
  'USDe • FRAX Fraxtal': '0x1e199dbe1f7aa237282fe941d5bcc3b167c8ce48',
  'USDe • GHO': '0x670a72e6D22b0956C0D2573288F82DCc5d6E3a61',
  'USDe • mkUSD': '0x1ab3D612EA7df26117554ddDD379764EBcE1A5Ad',
  'USDe • PYUSD': zeroAddress,
  'USDe • USDa': '0x68984391a8ce6203067ee65ff7eed38c7e0d2d88',
  'USDe • USDB': zeroAddress,
  'USDe • USDC': '0x02950460E2b9529D0E00284A5fA2d7bDF3fA4d72',
  'USDe • USDC Arbitrum': '0x1c34204FCFE5314Dcf53BE2671C02c35DB58B4e3',
  'USDe • USDC.e': zeroAddress,
  'USDe • USDT': '0x435664008F38B0650fBC1C9fc971D0A3Bc2f1e47',
  'USDe • USDtb': '0x2D9Bf9C1beFd77C094461df615bdbE905895f7C6',
  'USDe • USDX': '0x096a8865367686290639bc50bf8d85c0110d9fea',
  'USDe • waGHO': zeroAddress,
  USDeFraxtal: '0x5d3a1ff2b6bab83b63cd9ad0787074081a52ef34',
  USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  USDtb: '0xC139190F447e929f090Edeb554D95AbB8b18aC1C',
  USDX: '0xb2F30A7C980f052f02563fb518dcc39e6bf38175',
  waGHO: '0xd9fba68d89178e3538e708939332c79efc540179',
  WBETH: '0xa2E3356610840701BDf5611a53974510Ae27E2e1',
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
}

export const TOKEN_SYMBOLS: Record<TokenType, string> = {
  AaveLidoSusde: 'aEthLidosUSDe',
  'Ambient USDe • ETH': 'AmbientUSDeETH',
  'Angle USDa': 'AngleUSDa',
  ASUSDe: 'ASUSDe',
  AUSD: 'AUSD',
  AUSDe: 'AUSDe',
  'Avalon USDa': 'AvalonUSDa',
  BBUSD: 'BBUSD',
  BTC: 'BTC',
  cmETH: 'cmETH',
  crvUSD: 'crvUSD',
  DAI: 'DAI',
  ENA: 'ENA',
  ETH: 'ETH',
  'Ether.fi Liquid': 'liquidUSD',
  eUSD: 'eUSD',
  FDUSD: 'FDUSD',
  FRAX: 'FRAX',
  FRAXFraxtal: 'FRAXFraxtal',
  GHO: 'GHO',
  GYD: 'GYD',
  'Locked ENA': 'Locked ENA',
  mETH: 'mETH',
  mkUSD: 'mkUSD',
  'MOE USDe • AUSD': 'MOEUSDeAUSD',
  'MOE USDe • cmETH': 'MOEUSDecmETH',
  'MOE USDe • mETH': 'MOEUSDemETH',
  'MOE USDe • sUSDe': 'MOEUSDesUSDe',
  'MOE USDe • USDC': 'MOEUSDeUSDC',
  'MOE USDe • USDT': 'MOEUSDeUSDT',
  'PT ENA': 'PTENA',
  'PT ENA OCT': 'PTENAOCT',
  'PT rsENA': 'PTrsENA',
  PTUSDeJuly: 'USDe',
  RateX: 'RateX',
  rsENA: 'rsENA',
  rsUSDe: 'rsUSDe',
  scrvUSD: 'scrvUsd',
  sDAI: 'sDAI',
  sENA: 'sENA',
  sFRAX: 'sFRAX',
  SolvBTC: 'SolvBTC',
  stETH: 'stETH',
  sUSDe: 'sUSDe',
  'sUSDe • crvUSD': 'sUSDeCrvUSD',
  'sUSDe • FRAX Fraxtal': 'sUSDEFRAXFraxtal',
  'sUSDe • GYD': 'sUSDeGYD',
  'sUSDe • PYUSD': 'sUSDePYUSD',
  'sUSDe • scrvUSD': 'sUSDeScrvusd',
  'sUSDe • sDAI': 'sUSDeSdai',
  'sUSDe • sDAI • sFRAX': 'sUSDeSdaiSfrax',
  'sUSDe • sFRAX': 'sUSDeSfrax',
  'sUSDe • USDB': 'sUSDeUsdb',
  'sUSDe • USDC': 'sUSDeUsdc',
  'sUSDe • USDC • USDT': 'sUSDeUsdcUsdt',
  'sUSDe • USDC.e': 'sUSDeUsdc.e',
  'sUSDe • USDT': 'sUSDeUsdt',
  'sUSDe • wstETH': 'sUSDeWsteth',
  sUSDeFraxtal: 'sUSDeFraxtal',
  'Tempest USDe • ETH': 'TempestUSDeETH',
  USDB: 'USDB',
  USDC: 'USDC',
  USDe: 'USDe',
  'USDe • crvUSD': 'USDecrvUSD',
  'USDe • DAI': 'USDeDAI',
  'USDe • FDUSD': 'USDeFDUSD',
  'USDe • FRAX': 'FRAXUSDe',
  'USDe • FRAX Fraxtal': 'USDeFRAXFraxtal',
  'USDe • GHO': 'GHOUSDe',
  'USDe • mkUSD': 'mkUSDUSDe',
  'USDe • PYUSD': 'USDePYUSD',
  'USDe • USDa': 'USDeUSDa',
  'USDe • USDB': 'USDeUSDB',
  'USDe • USDC': 'USDeUSDC',
  'USDe • USDC Arbitrum': 'USDEUSDC',
  'USDe • USDC.e': 'USDeUSDC.e',
  'USDe • USDT': 'USDeUSDT',
  'USDe • USDtb': 'USDeUSDtb',
  'USDe • USDX': 'USDeUSDX',
  'USDe • waGHO': '',
  USDeFraxtal: 'USDeFraxtal',
  USDT: 'USDT',
  USDtb: 'USDtb',
  USDX: 'USDX',
  waGHO: 'waGHO',
  WBETH: 'wBETH',
  WETH: 'WETH',
}

export const TOKENS_BY_ADDRESS: Record<Address, TokenType> = Object.entries(TOKEN_ADDRESSES).reduce(
  (a, c) => ({ ...a, [c[1]]: c[0] }),
  {},
)

export const TOKEN_ICONS: Record<TokenType, string | string[]> = {
  AaveLidoSusde: 'shared/sUSDe.svg',
  'Ambient USDe • ETH': ['shared/usde.svg', 'ethereum.svg'],
  'Angle USDa': 'usda-angle.svg',
  ASUSDe: 'shared/sUSDe.svg',
  AUSD: 'ausd.png',
  AUSDe: 'shared/usde.svg',
  'Avalon USDa': 'usda-avalon.svg',
  BBUSD: 'bbusd.png',
  BTC: 'btc.webp',
  cmETH: 'cmeth.png',
  crvUSD: 'crvUSD.png',
  DAI: 'dai.webp',
  ENA: 'shared/ethena.svg',
  ETH: 'ethereum.svg',
  'Ether.fi Liquid': 'etherfi-liquid.png',
  eUSD: 'shared/eusd.svg',
  FDUSD: 'fdusd.svg',
  FRAX: 'frax.svg',
  FRAXFraxtal: 'frax.svg',
  GHO: 'gho.png',
  GYD: 'gyd.png',
  'Locked ENA': 'locked-ena.svg',
  mETH: 'meth.webp',
  mkUSD: 'mkUSD.png',
  'MOE USDe • AUSD': ['shared/usde.svg', 'ausd.png'],
  'MOE USDe • cmETH': ['shared/usde.svg', 'cmeth.png'],
  'MOE USDe • mETH': ['shared/usde.svg', 'meth.webp'],
  'MOE USDe • sUSDe': ['shared/usde.svg', 'shared/sUSDe.svg'],
  'MOE USDe • USDC': ['shared/usde.svg', 'usdc.png'],
  'MOE USDe • USDT': ['shared/usde.svg', 'usdt.png'],
  'PT ENA': 'shared/ethena.svg',
  'PT ENA OCT': 'shared/ethena.svg',
  'PT rsENA': 'shared/rsENA.svg',
  PTUSDeJuly: 'shared/usde.svg',
  RateX: 'ratex.svg',
  rsENA: 'shared/rsENA.svg',
  rsUSDe: 'shared/rsUSDe.svg',
  scrvUSD: 'scrvusd.png',
  sDAI: 'sdai.png',
  sENA: 'shared/sENA.svg',
  sFRAX: 'frax.svg',
  SolvBTC: 'solvbtc.png',
  stETH: 'lido.png',
  sUSDe: 'shared/sUSDe.svg',
  'sUSDe • crvUSD': ['shared/sUSDe.svg', 'crvUSD.png'],
  'sUSDe • FRAX Fraxtal': ['shared/sUSDe.svg', 'frax.svg'],
  'sUSDe • GYD': ['shared/sUSDe.svg', 'gyd.png'],
  'sUSDe • PYUSD': ['shared/sUSDe.svg', 'pyusd.png'],
  'sUSDe • scrvUSD': ['shared/sUSDe.svg', 'scrvusd.png'],
  'sUSDe • sDAI': ['shared/sUSDe.svg', 'sdai.png'],
  'sUSDe • sDAI • sFRAX': ['shared/sUSDe.svg', 'sdai.png', 'frax.svg'],
  'sUSDe • sFRAX': ['shared/sUSDe.svg', 'frax.svg'],
  'sUSDe • USDB': ['shared/sUSDe.svg', 'usdb.svg'],
  'sUSDe • USDC': ['shared/sUSDe.svg', 'usdc.png'],
  'sUSDe • USDC • USDT': ['shared/sUSDe.svg', 'usdc.png', 'usdt.png'],
  'sUSDe • USDC.e': ['shared/sUSDe.svg', 'usdc.png'],
  'sUSDe • USDT': ['shared/sUSDe.svg', 'usdt.png'],
  'sUSDe • wstETH': ['shared/sUSDe.svg', 'wsteth.png'],
  sUSDeFraxtal: '/shared/sUSDe.svg',
  'Tempest USDe • ETH': ['shared/usde.svg', 'ethereum.svg'],
  USDB: 'usdb.svg',
  USDC: 'usdc.png',
  USDe: 'shared/usde.svg',
  'USDe • crvUSD': ['shared/usde.svg', 'crvUSD.png'],
  'USDe • DAI': ['shared/usde.svg', 'dai.webp'],
  'USDe • FDUSD': ['shared/usde.svg', 'fdusd.svg'],
  'USDe • FRAX': ['shared/usde.svg', 'frax.svg'],
  'USDe • FRAX Fraxtal': ['shared/usde.svg', 'frax.svg'],
  'USDe • GHO': ['shared/usde.svg', 'gho.png'],
  'USDe • mkUSD': ['shared/usde.svg', 'mkUSD.png'],
  'USDe • PYUSD': ['shared/usde.svg', 'pyusd.png'],
  'USDe • USDa': ['shared/usde.svg', 'usda-avalon.svg'],
  'USDe • USDB': ['shared/usde.svg', 'usdb.svg'],
  'USDe • USDC': ['shared/usde.svg', 'usdc.png'],
  'USDe • USDC Arbitrum': ['shared/usde.svg', 'usdc.png'],
  'USDe • USDC.e': ['shared/usde.svg', 'usdc.png'],
  'USDe • USDT': ['shared/usde.svg', 'usdt.png'],
  'USDe • USDtb': ['shared/usde.svg', 'shared/usdtb.svg'],
  'USDe • USDX': ['shared/usde.svg', 'usdx.png'],
  'USDe • waGHO': ['shared/usde.svg', 'wagho.png'],
  USDeFraxtal: '/shared/usde.svg',
  USDT: 'usdt.png',
  USDtb: 'shared/usdtb.svg',
  USDX: 'usdx.png',
  waGHO: 'wagho.png',
  WBETH: 'wbeth.png',
  WETH: 'weth.png',
}

export const COIN_GECKO_SYMBOLS_MAP: Record<TokenType, CoinGeckoSymbol | null> = {
  AaveLidoSusde: null,
  'Ambient USDe • ETH': null,
  'Angle USDa': null,
  ASUSDe: null,
  AUSD: null,
  AUSDe: null,
  'Avalon USDa': null,
  BBUSD: null,
  BTC: null,
  cmETH: null,
  crvUSD: 'crvusd',
  DAI: 'dai',
  ENA: 'ethena',
  ETH: 'ethereum',
  'Ether.fi Liquid': null,
  eUSD: null,
  FDUSD: 'first-digital-usd',
  FRAX: 'frax',
  FRAXFraxtal: null,
  GHO: 'gho',
  GYD: null,
  'Locked ENA': null,
  mETH: 'mantle-staked-ether',
  mkUSD: 'prisma-mkusd',
  'MOE USDe • AUSD': null,
  'MOE USDe • cmETH': null,
  'MOE USDe • mETH': null,
  'MOE USDe • sUSDe': null,
  'MOE USDe • USDC': null,
  'MOE USDe • USDT': null,
  'PT ENA': null,
  'PT ENA OCT': null,
  'PT rsENA': null,
  PTUSDeJuly: null,
  RateX: null,
  rsENA: null,
  rsUSDe: null,
  scrvUSD: null,
  sDAI: 'savings-dai',
  sENA: null,
  sFRAX: null,
  SolvBTC: null,
  stETH: 'staked-ether',
  sUSDe: null,
  'sUSDe • crvUSD': null,
  'sUSDe • FRAX Fraxtal': null,
  'sUSDe • GYD': null,
  'sUSDe • PYUSD': null,
  'sUSDe • scrvUSD': null,
  'sUSDe • sDAI': null,
  'sUSDe • sDAI • sFRAX': null,
  'sUSDe • sFRAX': null,
  'sUSDe • USDB': null,
  'sUSDe • USDC': null,
  'sUSDe • USDC • USDT': null,
  'sUSDe • USDC.e': null,
  'sUSDe • USDT': null,
  'sUSDe • wstETH': null,
  sUSDeFraxtal: null,
  'Tempest USDe • ETH': null,
  USDB: null,
  USDC: 'usd-coin',
  USDe: null,
  'USDe • crvUSD': null,
  'USDe • DAI': null,
  'USDe • FDUSD': null,
  'USDe • FRAX': null,
  'USDe • FRAX Fraxtal': null,
  'USDe • GHO': null,
  'USDe • mkUSD': null,
  'USDe • PYUSD': null,
  'USDe • USDa': null,
  'USDe • USDB': null,
  'USDe • USDC': null,
  'USDe • USDC Arbitrum': null,
  'USDe • USDC.e': null,
  'USDe • USDT': null,
  'USDe • USDtb': null,
  'USDe • USDX': null,
  'USDe • waGHO': null,
  USDeFraxtal: null,
  USDT: 'tether',
  USDtb: 'usdtb',
  USDX: null,
  waGHO: null,
  WBETH: 'wrapped-beacon-eth',
  WETH: 'weth',
}

export const COIN_GECKO_SYMBOLS: CoinGeckoSymbol[] = Object.values(COIN_GECKO_SYMBOLS_MAP).filter(
  symbol => !!symbol,
) as CoinGeckoSymbol[]

export const TOKEN_DECIMALS: Record<string, number> = {
  'Ether.fi Liquid': 6,
  USDC: 6,
  USDT: 6,
}

export const TOKEN_ABI: Record<string, Abi> = {
  sENA: STAKED_USDE_V2_ABI,
  sUSDe: STAKED_USDE_V2_ABI,
  USDe: USDE_ABI,
  USDT: USDT_ABI,
}

export const METH_YIELD = 3.1
