import Link from 'next/link'

import { SplineViewer } from '../components/SplineViewer'

export const Custom404 = ({ title = 'Page Not Found' }: { title?: string }) => {
  return (
    <>
      <SplineViewer
        scene="https://prod.spline.design/qR3v22CgU7PE4QiN/scene.splinecode?v=1"
        className="h-[100vh] min-h-[100vh] block opacity-100 sm:block max-h-[100vh] bg-black"
      />
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 lg:-translate-y-1/4 min-w-[250px]">
        <h1 className="text-4xl md:text-6xl font-semibold leading-tight text-white text-center">{title}</h1>
        <Link
          href="/"
          className="block text-base rounded-lg py-2 px-10 bg-white text-neutral-950 font-bold mt-[40px] text-center w-fit mx-auto"
        >
          Back to Home
        </Link>
      </div>
    </>
  )
}
