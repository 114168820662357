globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"686a20e7a1f8a7256ebb1bdd529f78f0ec51719d"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs'

Sentry.init({
  debug: false,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      networkDetailAllowUrls: [window.location.origin, 'https://api.coingecko.com/api/v3/simple/price'],
    }),
  ],
  replaysOnErrorSampleRate: 0.25,
  sampleRate: 0.5,
})
