'use client'

import { AppSetup } from '@ethena/shared-ui/src/components/AppSetup'
import { Route } from '@ethena/shared-ui/src/components/NavBar/NavBar'
import { DISCORD_LINK } from '@ethena/shared-ui/src/constants/socials'
import { TRANSPORTS } from '@ethena/shared-ui/src/constants/transports'
import { DAPP_URL, GITBOOK_URL, NETWORK_URL } from '@ethena/shared-ui/src/constants/url'
import { useIdle } from '@ethena/shared-ui/src/hooks/useIdle'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect } from 'react'
import { createConfig, WagmiProvider } from 'wagmi'
import { localhost, mainnet } from 'wagmi/chains'

import { IS_GERMAN } from '@/constants'
import useUserStore from '@/store/user-store'

import { LandingWrapper } from './landing-wrapper'

interface GlobalContainerProps {
  children: React.ReactNode
}

export const GlobalContainer = ({ children }: GlobalContainerProps) => {
  const { setIsIdle } = useUserStore()
  const idle = useIdle(120_000)

  useEffect(() => {
    setIsIdle(idle)
  }, [idle, setIsIdle])

  const queryClient = new QueryClient()

  const wagmiConfig = createConfig({
    chains: [process.env.NEXT_PUBLIC_PLAYWRIGHT_ENABLED === 'true' ? localhost : mainnet],
    ssr: true,
    transports: TRANSPORTS,
  })

  const hiddenRoutes = ['/restricted']

  const routes = [
    { cutToMenuBreakpoint: 'md:block', route: '/', title: 'Home' },
    ...(!IS_GERMAN
      ? [
          { cutToMenuBreakpoint: 'lg:block', route: '/ecosystem', title: 'Ecosystem' },
          {
            cutToMenuBreakpoint: 'lg:block',
            route: NETWORK_URL,
            title: 'Network',
          },
          {
            cutToMenuBreakpoint: 'xl:block',
            route: DAPP_URL + '/dashboards/transparency',
            title: 'Transparency',
          },
        ]
      : [
          {
            cutToMenuBreakpoint: 'lg:block',
            route: '',
            routes: [
              {
                route: 'https://app.ethena.fi/dashboards/transparency',
                title: 'Backing',
              },
              {
                route: '/audit-report',
                title: 'Audit Report',
              },
              {
                route: '/significant-events',
                title: 'Significant Events',
              },
            ],
            title: 'USDe Holder Info',
          },
          {
            cutToMenuBreakpoint: 'lg:block',
            route: 'https://app.ethena.fi/buy',
            title: 'Buy USDe',
          },
        ]),
    {
      cutToMenuBreakpoint: 'xl:block',
      route: GITBOOK_URL,
      title: 'Docs',
    },
    ...(!IS_GERMAN
      ? [
          {
            cutToMenuBreakpoint: 'xl:block',
            route: '',
            routes: [
              { route: DISCORD_LINK, title: 'Discord' },
              { route: '/leaderboard', title: 'Leaderboard' },
              { route: '/referrals', title: 'Referrals' },
              { route: 'https://gov.ethenafoundation.com/', title: 'Governance' },
            ],
            title: 'Community',
          },
        ]
      : []),
  ] as Route[]

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <AppSetup>
          <LandingWrapper routes={routes} hiddenRoutes={hiddenRoutes}>
            {children}
          </LandingWrapper>
        </AppSetup>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
