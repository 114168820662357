'use client'

import useSWR from 'swr'

import { SUSDE_TOOLTIP } from '../constants/susde-tooltip'
import { useFormatYieldTooltip } from '../hooks/useFormatYieldTooltip'
import { FetchError } from '../types'
import { YieldResponse } from '../utils/edge/protocol-and-staking-yield'
import { formatNumber, NumberType } from '../utils/formatNumbers'

export const useSusdeYieldTooltipData = () => {
  const { data: yieldData } = useSWR<YieldResponse, FetchError>('/api/yields/protocol-and-staking-yield')

  const yieldStr = yieldData?.stakingYield?.value
    ? formatNumber(Math.round(yieldData.stakingYield.value), NumberType.NoDecimals) + '%'
    : undefined

  const [yieldApy, tooltip] = useFormatYieldTooltip({
    baseTooltip: SUSDE_TOOLTIP,
    yieldData: yieldData?.stakingYield ?? { lastUpdated: '', value: 0 },
  })

  return { tooltip, yieldApy, yieldStr }
}
