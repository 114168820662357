'use client'

import clsx from 'clsx'

interface PlaceholderProps {
  isLoading: boolean
  classes?: string
  children: React.ReactNode
}

export const Placeholder = ({ children, classes = 'w-24 h-4', isLoading }: PlaceholderProps) => {
  return isLoading ? (
    <div className={clsx('rounded bg-gray-700', classes)} style={{ opacity: 'var(--placeholder-opacity)' }}></div>
  ) : (
    <>{children}</>
  )
}

export default Placeholder
